<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <listitemspage
          :selitem="selitem"
          :qSelect="Qselect"
          :qSelectall="Qselectall"
          :qDelete="Qdelete"
          :PageResponse="'transactions'"
          :AllResponse="'alltrans'"
          :headers="headers"
          :field="'CREATED_AT'"
          :order="'ASC'"
          :filename="'Transactions'"
          :sheetname="'Transactions'"
          :showedit="false"
          :TypeScope="1"
          :VarScope="
            $store.state.isadmin || $store.state.auth.includes('05003')
              ? 0
              : parseInt($store.state.me.id)
          "
          :del_disable="trans.statut_id > 1"
          @rowselect="TransChange"
          @open="TransOpen"
          @hrf="TransOpen"
          :Add="$store.state.auth.includes('02003') || $store.state.isadmin"
          :del="false"
          :key="klist"
          :search_elm="filtre"
          :load="loading"
        >
        </listitemspage>
      </v-col>
    </v-row>
    <transform
      :item="trans"
      :showForm="!isTransClosed"
      :currencies="currencies"
      :users="users"
      :modes="modes"
      :tiers="tiers"
      :contacts="contacts"
      :trans_type="'e'"
      @close="isTransClosed = true"
      @validate="trans_valid"
      :key="ktf"
      :modify="
        ((trans.statut_id == 1 && trans.active == 1) || trans.id < 0) && editer
      "
    >
    </transform>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <template>
      <confirmdialog ref="confirm" />
    </template>
  </v-container>
</template>

<script>
import TRANSACTIONS from "../graphql/Trans/TRANSACTIONS.gql";
import ALLTRANSACTIONS from "../graphql/Trans/ALLTRANSACTIONS.gql";
import DELETE_OPR from "../graphql/Trans/DELETE_OPR.gql";
import DATA from "../graphql/Trans/DATA.gql";

export default {
  components: {
    listitemspage: () => import("../components/ListItemsPages.vue"),
    transform: () => import("../components/TransForm.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
  },
  props: {
    transtype: Number,
  },
  data: () => ({
    selitem: {},
    unfilter: false,
    width: "1000px",
    orientation: "p",
    isTransClosed: true,
    headers: [
      {
        text: "Code",
        align: "start",
        value: "no_opr",
        enum: "NO_OPR",
        slot: "href",
        selected: true,
        index: 0,
      },
      {
        text: "Operateur",
        align: "start",
        value: "tier_name",
        enum: "TIER_NAME",
        selected: true,
        index: 1,
      },
      {
        text: "Operation",
        value: "operation",
        enum: "OPERATION",
        slot: "chip",
        color: "color",
        selected: true,
        index: 2,
      },
      {
        text: "Date Opr",
        value: "opr_date",
        enum: "OPR_DATE",
        selected: true,
        slot: "date",
        index: 3,
      },
      {
        text: "Date Max",
        value: "date_limite",
        enum: "DATE_LIMITE",
        selected: true,
        slot: "date",
        expire: true,
        index: 3,
      },
      {
        text: "Montant",
        value: "montant",
        enum: "MONTANT",
        selected: true,
        slot: "cur",
        align: "end",
        index: 4,
      },
      {
        text: "Monnaie",
        value: "currency",
        enum: "CURRENCY",
        selected: true,
        index: 5,
      },

      {
        text: "Montant DA",
        value: "mont_dz",
        enum: "MONT_DZ",
        selected: true,
        slot: "cur",
        align: "end",
        index: 6,
      },
      {
        text: "Mode",
        value: "type_paye",
        enum: "TYPE_PAYE",
        selected: true,
        index: 7,
      },

      {
        text: "Agent",
        value: "user_name",
        enum: "USER_NAME",
        selected: true,
        index: 8,
      },
      {
        text: "Contact",
        value: "contact",
        enum: "CONTACT",
        selected: true,
        index: 9,
      },
      {
        text: "Status",
        value: "status_name",
        enum: "STATUS_NAME",
        slot: "chip",
        color: "status_color",
        selected: true,
        index: 10,
      },
    ],
    ktf: 2000,
    tiers: [],
    contacts: [],
    modes: [],
    users: [],
    Qselect: {},
    Qselectall: {},
    Qdelete: {},
    Qdetaildelete: {},
    trans: {},
    loading: false,
    title: "",
    frml: 600,
    countrys: [],
    editer: false,

    klist: 0,
    ktrans: 100,

    currencies: [],
    alert: false,
    type_alert: "info",
    message: "",
    listheaders: [],

    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    filtre: "",
    year: null,
  }),

  computed: {
    keytrans() {
      return this.trans.id;
    },
  },
  watch: {
    transtype: {
      handler() {
        this.get_data();
        this.klist++;
      },
    },
    "$store.state.filtre": {
      handler() {
        if (this.$store.state.filtre) {
          this.filtre = this.$store.state.filtre;
          this.klist++;
        }
      },
    },
  },
  created() {
    if (this.$route.params.filtre) {
      this.filtre = this.$route.params.filtre;
      this.klist++;
    }
  },

  async mounted() {
    this.Qselect = TRANSACTIONS;
    this.Qselectall = ALLTRANSACTIONS;
    this.Qdelete = DELETE_OPR;
    this.year = this.$store.state.exercice;
    this.editer =
      this.$store.state.auth.includes("02003") || this.$store.state.isadmin;
    await this.get_data();
  },

  methods: {
    async requette(query, v, f) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: f,
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    trans_valid() {
      this.$store.dispatch("Changed", true);
      this.$emit("refresh", this.editedItem);
    },
    trans_add(item) {
      this.trans = item;
      this.selitem = item;
    },

    async maj(query, v) {
      let r;
      this.loading = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.loading = false;
        });
      return r;
    },
    async get_data() {
      this.loading = true;

      let r = await this.requette(DATA);
      if (r) {
        this.currencies = r.currencies;
        this.modes = r.modes;
        this.tiers = r.alltiers;
        this.contacts = r.contacts;
        this.users = r.users;
      }
      //

      this.loading = false;
    },
    TransOpen(item) {
      this.trans = item;
      this.isTransClosed = false;
      this.ktf++;
    },
    async TransChange(item) {
      this.trans = item;
      this.selitem = item;
      this.ktrans++;
    },
  },
};
</script>
